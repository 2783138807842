<template>
  <div class="theme-dashboard-view">
    <h1>{{ $t("drivers_title") }}</h1>

    <!-- Loading Info -->
    <div class="d-flex justify-content-center">
      <LoadingIndicator :active="loading" />
    </div>
    <div v-show="load_error">
      <div class="justify-content-center text-center text-danger my-4">
        <h4>{{ load_error }}</h4>
      </div>
    </div>

    <div class="my-4" v-show="!loading">
      <div v-show="drivers_data.length == 0" class="text-center justify-content-center" style="font-size: 25px">
        {{ $t("drivers_none") }}
      </div>
      <Table :rows="drivers_data" :contain="[
        'id',
        'first_name',
        'last_name',
        'phone_number',
      ]">
        <template v-slot:id-title>{{ $t("drivers_table_id") }} </template>

        <template v-slot:first_name-title> {{ $t("drivers_table_first_name") }}</template>

        <template v-slot:last_name-title> {{ $t("drivers_table_last_name") }}</template>

        <template v-slot:phone_number-title> {{ $t("drivers_table_phone_number") }}</template>
      </Table>
      <div v-show="drivers_data.length != 0">
        <div class="d-flex justify-content-center">
          <PageNav :pages="pages" :current_page="current_page" @click-page="getDrivers" @next-page="nextPage"
            @prev-page="prevPage" @goto-first="gotoFirstPage" @goto-last="gotoLastPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PageNav from "@/components/PageNav.vue";

// Services
import DriverService from "@/services/drivers.js";

export default {
  name: "Drivers",
  components: {
    Table,
    LoadingIndicator,
    PageNav,
  },
  data() {
    return {
      loading: false,
      load_error: "",
      drivers_data: [],

      current_page: 1,
      pages: 1,
    };
  },
  methods: {
    // Page Navigation
    nextPage() {
      if (this.current_page !== this.pages) {
        this.getDrivers(this.current_page + 1);
      }
    },
    prevPage() {
      if (this.current_page !== 1) {
        this.getDrivers(this.current_page - 1);
      }
    },
    gotoFirstPage() {
      if (this.current_page !== 1) {
        this.getDrivers(1);
      }
    },
    gotoLastPage() {
      if (this.current_page !== this.pages) {
        this.getDrivers(this.pages);
      }
    },

    getDrivers(page) {
      this.loading = true;
      this.load_error = "";
      DriverService.getDrivers(page).then(
        (response) => {
          this.loading = false;
          this.current_page = page;
          console.log(response);
          this.drivers_data = response.data.data.drivers;
          this.pages = response.data.data.total_pages;
        },
        (error) => {
          this.loading = false;
          console.log("FAIL");
          this.load_error =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
  mounted() {
    this.getDrivers(1);
  },
};
</script>
